import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "title" })} />
  </Layout>
)

export default injectIntl(IndexPage)
